export const LiqaOptions = {
    "en": {
        "source_selection": {
            "title": "Select the capture option",
            "camera_permissions_warning": {
                "title": "Camera access denied",
                "instructions_reset": "Update browser's camera permissions for this app to use a camera or upload a photo from the device",
                "instructions_reload": "Update browser's camera permissions for this app to use a camera or upload a photo from the device"
            },
            "camera_video": "Take a photo",
            "upload_photo": "Upload from device"
        },
        "face_live_video": {
            "face_displaced": "Place face inside frame",
            "face_placement_fixed": "Perfect!",
            "face_distance_far": "Move closer",
            "face_distance_close": "Move further away",
            "face_distance_fixed": "Perfect!",
            "face_angle_deviation": "Turn face to camera",
            "face_angle_fixed": "Perfect!",
            "lighting_insufficient": "Find a brighter place",
            "lighting_excessive": "Find a darker place",
            "lighting_angle_deviation": "Turn to the light source",
            "lighting_fixed": "Perfect!",
            "capturing": "Look at the camera"
        },
        "face_180_live_video": {
            "face_displaced": "Place face inside frame",
            "face_placement_fixed": "Perfect!",
            "face_distance_far": "Move closer",
            "face_distance_close": "Move further away",
            "face_distance_fixed": "Perfect!",
            "face_angle_deviation": "Turn face to camera",
            "face_angle_fixed": "Perfect!",
            "lighting_insufficient": "Find a brighter place",
            "lighting_excessive": "Find a darker place",
            "lighting_angle_deviation": "Turn to the light source",
            "lighting_fixed": "Perfect!",
            "face_pitch_angle_deviation": "Turn face to camera",
            "capturing_front": "Stay still",
            "capturing_left": "Move your head slowly\nto scan your skin",
            "capturing_right": "Move your head slowly\nto scan your skin"
        },
        "hair_live_video": {
            "face_displaced": "Place face inside frame",
            "face_placement_fixed": "Perfect!",
            "face_distance_close": "Move further away",
            "face_distance_fixed": "Perfect!",
            "lighting_insufficient": "Find a brighter place",
            "lighting_fixed": "Perfect!",
            "hair_not_visible": "Make your hair visible",
            "hair_visibility_fixed": "Perfect!",
            "face_angle_deviation": "Turn face to camera",
            "face_angle_fixed": "Perfect!",
            "capturing": "Make your hair visible\nPhoto will be collected automatically"
        },
        "preview": {
            "title": "Well done!",
            "text": "Check how beautiful you are",
            "retake": "Retake",
            "submit": "Submit"
        }
    },
    "de": {
        "source_selection": {
            "title": "Wählen Sie die Aufnahmemöglichkeit",
            "camera_permissions_warning": {
                "title": "Zugriff auf die Kamera verweigert",
                "instructions_reset": "Aktualisieren Sie die Kameraeinstellungen Ihres Browsers, um eine Kamera zu verwenden oder ein Foto vom Gerät hochzuladen",
                "instructions_reload": "Aktualisieren Sie die Kameraeinstellungen Ihres Browsers, um eine Kamera zu verwenden oder ein Foto vom Gerät hochzuladen"
            },
            "camera_video": "Ein Foto machen",
            "upload_photo": "Vom Gerät hochladen"
        },
        "face_live_video": {
            "face_displaced": "Platzieren Sie das Gesicht im Rahmen",
            "face_placement_fixed": "Perfekt!",
            "face_distance_far": "Gehen Sie näher heran",
            "face_distance_close": "Gehen Sie weiter weg",
            "face_distance_fixed": "Perfekt!",
            "face_angle_deviation": "Drehen Sie das Gesicht zur Kamera",
            "face_angle_fixed": "Perfekt!",
            "lighting_insufficient": "Suchen Sie sich einen helleren Ort",
            "lighting_excessive": "Suchen Sie sich einen dunkleren Ort",
            "lighting_angle_deviation": "Drehen Sie sich zur Lichtquelle",
            "lighting_fixed": "Perfekt!",
            "capturing": "Schauen Sie in die Kamera"
        },
        "face_180_live_video": {
            "face_displaced": "Platzieren Sie das Gesicht im Rahmen",
            "face_placement_fixed": "Perfekt!",
            "face_distance_far": "Gehen Sie näher heran",
            "face_distance_close": "Gehen Sie weiter weg",
            "face_distance_fixed": "Perfekt!",
            "face_angle_deviation": "Drehen Sie das Gesicht zur Kamera",
            "face_angle_fixed": "Perfekt!",
            "lighting_insufficient": "Suchen Sie sich einen helleren Ort",
            "lighting_excessive": "Suchen Sie sich einen dunkleren Ort",
            "lighting_angle_deviation": "Drehen Sie sich zur Lichtquelle",
            "lighting_fixed": "Perfekt!",
            "face_pitch_angle_deviation": "Drehen Sie das Gesicht zur Kamera",
            "capturing_front": "Stillhalten",
            "capturing_left": "Bewegen Sie den Kopf langsam,\num Ihre Haut zu scannen",
            "capturing_right": "Bewegen Sie den Kopf langsam,\num Ihre Haut zu scannen"
        },
        "hair_live_video": {
            "face_displaced": "Platzieren Sie das Gesicht im Rahmen",
            "face_placement_fixed": "Perfekt!",
            "face_distance_close": "Gehen Sie weiter weg",
            "face_distance_fixed": "Perfekt!",
            "lighting_insufficient": "Suchen Sie sich einen helleren Ort",
            "lighting_fixed": "Perfekt!",
            "hair_not_visible": "Machen Sie Ihr Haar sichtbar",
            "hair_visibility_fixed": "Perfekt!",
            "face_angle_deviation": "Drehen Sie das Gesicht zur Kamera",
            "face_angle_fixed": "Perfekt!",
            "capturing": "Machen Sie Ihr Haar sichtbar\nFoto wird automatisch aufgenommen"
        },
        "preview": {
            "title": "Gut gemacht!",
            "text": "Sehen Sie, wie schön Sie sind",
            "retake": "Erneut aufnehmen",
            "submit": "Einreichen"
        }
    },
    "ja": {
        "source_selection": {
            "title": "キャプチャオプションを選択",
            "camera_permissions_warning": {
                "title": "カメラへのアクセスが拒否されました",
                "instructions_reset": "このアプリでカメラを使用するために、ブラウザのカメラ許可設定を更新するか、デバイスから写真をアップロードしてください",
                "instructions_reload": "このアプリでカメラを使用するために、ブラウザのカメラ許可設定を更新するか、デバイスから写真をアップロードしてください"
            },
            "camera_video": "写真を撮る",
            "upload_photo": "デバイスからアップロード"
        },
        "face_live_video": {
            "face_displaced": "顔を枠の中に配置してください",
            "face_placement_fixed": "完璧です！",
            "face_distance_far": "近づいてください",
            "face_distance_close": "遠ざかってください",
            "face_distance_fixed": "完璧です！",
            "face_angle_deviation": "顔をカメラに向けてください",
            "face_angle_fixed": "完璧です！",
            "lighting_insufficient": "より明るい場所を見つけてください",
            "lighting_excessive": "より暗い場所を見つけてください",
            "lighting_angle_deviation": "光源に向けてください",
            "lighting_fixed": "完璧です！",
            "capturing": "カメラを見てください"
        },
        "face_180_live_video": {
            "face_displaced": "顔を枠の中に配置してください",
            "face_placement_fixed": "完璧です！",
            "face_distance_far": "近づいてください",
            "face_distance_close": "遠ざかってください",
            "face_distance_fixed": "完璧です！",
            "face_angle_deviation": "顔をカメラに向けてください",
            "face_angle_fixed": "完璧です！",
            "lighting_insufficient": "より明るい場所を見つけてください",
            "lighting_excessive": "より暗い場所を見つけてください",
            "lighting_angle_deviation": "光源に向けてください",
            "lighting_fixed": "完璧です！",
            "face_pitch_angle_deviation": "顔をカメラに向けてください",
            "capturing_front": "そのまま動かないでください",
            "capturing_left": "ゆっくり頭を動かして、\n肌をスキャンしてください",
            "capturing_right": "ゆっくり頭を動かして、\n肌をスキャンしてください"
        },
        "hair_live_video": {
            "face_displaced": "顔を枠の中に配置してください",
            "face_placement_fixed": "完璧です！",
            "face_distance_close": "遠ざかってください",
            "face_distance_fixed": "完璧です！",
            "lighting_insufficient": "より明るい場所を見つけてください",
            "lighting_fixed": "完璧です！",
            "hair_not_visible": "髪を見えるようにしてください",
            "hair_visibility_fixed": "完璧です！",
            "face_angle_deviation": "顔をカメラに向けてください",
            "face_angle_fixed": "完璧です！",
            "capturing": "髪を見えるようにしてください\n写真は自動的に撮影されます"
        },
        "preview": {
            "title": "よくできました！",
            "text": "あなたの美しさを確認してください",
            "retake": "再撮影",
            "submit": "送信"
        }
    },
    "lt": {
        "source_selection": {
            "title": "Pasirinkite fiksavimo parinktį",
            "camera_permissions_warning": {
                "title": "Kameros prieiga atmesta",
                "instructions_reset": "Atnaujinkite naršyklės kameros leidimus, kad ši programa galėtų naudoti kamerą arba įkelkite nuotrauką iš įrenginio",
                "instructions_reload": "Atnaujinkite naršyklės kameros leidimus, kad ši programa galėtų naudoti kamerą arba įkelkite nuotrauką iš įrenginio"
            },
            "camera_video": "Nufotografuoti",
            "upload_photo": "Įkelti iš įrenginio"
        },
        "face_live_video": {
            "face_displaced": "Padėkite veidą rėmo viduje",
            "face_placement_fixed": "Puiku!",
            "face_distance_far": "Priartėkite",
            "face_distance_close": "Atsitraukite",
            "face_distance_fixed": "Puiku!",
            "face_angle_deviation": "Pasukite veidą link kameros",
            "face_angle_fixed": "Puiku!",
            "lighting_insufficient": "Raskite šviesesnę vietą",
            "lighting_excessive": "Raskite tamsesnę vietą",
            "lighting_angle_deviation": "Pasukite link šviesos šaltinio",
            "lighting_fixed": "Puiku!",
            "capturing": "Pažiūrėkite į kamerą"
        },
        "face_180_live_video": {
            "face_displaced": "Padėkite veidą rėmo viduje",
            "face_placement_fixed": "Puiku!",
            "face_distance_far": "Priartėkite",
            "face_distance_close": "Atsitraukite",
            "face_distance_fixed": "Puiku!",
            "face_angle_deviation": "Pasukite veidą link kameros",
            "face_angle_fixed": "Puiku!",
            "lighting_insufficient": "Raskite šviesesnę vietą",
            "lighting_excessive": "Raskite tamsesnę vietą",
            "lighting_angle_deviation": "Pasukite link šviesos šaltinio",
            "lighting_fixed": "Puiku!",
            "face_pitch_angle_deviation": "Pasukite veidą link kameros",
            "capturing_front": "Likite nejudėdami",
            "capturing_left": "Lėtai pasukite galvą,\nkad nuskaitytumėte odą",
            "capturing_right": "Lėtai pasukite galvą,\nkad nuskaitytumėte odą"
        },
        "hair_live_video": {
            "face_displaced": "Padėkite veidą rėmo viduje",
            "face_placement_fixed": "Puiku!",
            "face_distance_close": "Atsitraukite",
            "face_distance_fixed": "Puiku!",
            "lighting_insufficient": "Raskite šviesesnę vietą",
            "lighting_fixed": "Puiku!",
            "hair_not_visible": "Padarykite, kad plaukai būtų matomi",
            "hair_visibility_fixed": "Puiku!",
            "face_angle_deviation": "Pasukite veidą link kameros",
            "face_angle_fixed": "Puiku!",
            "capturing": "Padarykite, kad plaukai būtų matomi\nNuotrauka bus užfiksuota automatiškai"
        },
        "preview": {
            "title": "Puikiai padaryta!",
            "text": "Patikrinkite, kaip atrodote",
            "retake": "Pakartoti",
            "submit": "Pateikti"
        }
    },
    "nl": {
        "source_selection": {
            "title": "Selecteer de opname-optie",
            "camera_permissions_warning": {
                "title": "Cameratoegang geweigerd",
                "instructions_reset": "Werk de cameramachtigingen van de browser bij om een camera te gebruiken of een foto vanaf het apparaat te uploaden",
                "instructions_reload": "Werk de cameramachtigingen van de browser bij om een camera te gebruiken of een foto vanaf het apparaat te uploaden"
            },
            "camera_video": "Maak een foto",
            "upload_photo": "Uploaden vanaf apparaat"
        },
        "face_live_video": {
            "face_displaced": "Plaats gezicht binnen het kader",
            "face_placement_fixed": "Perfect!",
            "face_distance_far": "Kom dichterbij",
            "face_distance_close": "Ga verder weg",
            "face_distance_fixed": "Perfect!",
            "face_angle_deviation": "Draai gezicht naar de camera",
            "face_angle_fixed": "Perfect!",
            "lighting_insufficient": "Zoek een lichtere plek",
            "lighting_excessive": "Zoek een donkere plek",
            "lighting_angle_deviation": "Draai naar de lichtbron",
            "lighting_fixed": "Perfect!",
            "capturing": "Kijk naar de camera"
        },
        "face_180_live_video": {
            "face_displaced": "Plaats gezicht binnen het kader",
            "face_placement_fixed": "Perfect!",
            "face_distance_far": "Kom dichterbij",
            "face_distance_close": "Ga verder weg",
            "face_distance_fixed": "Perfect!",
            "face_angle_deviation": "Draai gezicht naar de camera",
            "face_angle_fixed": "Perfect!",
            "lighting_insufficient": "Zoek een lichtere plek",
            "lighting_excessive": "Zoek een donkere plek",
            "lighting_angle_deviation": "Draai naar de lichtbron",
            "lighting_fixed": "Perfect!",
            "face_pitch_angle_deviation": "Draai gezicht naar de camera",
            "capturing_front": "Blijf stil",
            "capturing_left": "Draai je hoofd langzaam\nom je huid te scannen",
            "capturing_right": "Draai je hoofd langzaam\nom je huid te scannen"
        },
        "hair_live_video": {
            "face_displaced": "Plaats gezicht binnen het kader",
            "face_placement_fixed": "Perfect!",
            "face_distance_close": "Ga verder weg",
            "face_distance_fixed": "Perfect!",
            "lighting_insufficient": "Zoek een lichtere plek",
            "lighting_fixed": "Perfect!",
            "hair_not_visible": "Maak je haar zichtbaar",
            "hair_visibility_fixed": "Perfect!",
            "face_angle_deviation": "Draai gezicht naar de camera",
            "face_angle_fixed": "Perfect!",
            "capturing": "Maak je haar zichtbaar\nDe foto wordt automatisch gemaakt"
        },
        "preview": {
            "title": "Goed gedaan!",
            "text": "Bekijk hoe mooi je bent",
            "retake": "Opnieuw",
            "submit": "Verzenden"
        }
    },
    "pl": {
        "source_selection": {
            "title": "Wybierz opcję przechwytywania",
            "camera_permissions_warning": {
                "title": "Odmowa dostępu do kamery",
                "instructions_reset": "Zaktualizuj uprawnienia kamery w przeglądarce, aby ta aplikacja mogła używać kamery lub przesłać zdjęcie z urządzenia",
                "instructions_reload": "Zaktualizuj uprawnienia kamery w przeglądarce, aby ta aplikacja mogła używać kamery lub przesłać zdjęcie z urządzenia"
            },
            "camera_video": "Zrób zdjęcie",
            "upload_photo": "Prześlij z urządzenia"
        },
        "face_live_video": {
            "face_displaced": "Umieść twarz w ramce",
            "face_placement_fixed": "Idealnie!",
            "face_distance_far": "Podejdź bliżej",
            "face_distance_close": "Odsuń się dalej",
            "face_distance_fixed": "Idealnie!",
            "face_angle_deviation": "Obróć twarz do kamery",
            "face_angle_fixed": "Idealnie!",
            "lighting_insufficient": "Znajdź jaśniejsze miejsce",
            "lighting_excessive": "Znajdź ciemniejsze miejsce",
            "lighting_angle_deviation": "Obróć się w stronę źródła światła",
            "lighting_fixed": "Idealnie!",
            "capturing": "Spójrz w kamerę"
        },
        "face_180_live_video": {
            "face_displaced": "Umieść twarz w ramce",
            "face_placement_fixed": "Idealnie!",
            "face_distance_far": "Podejdź bliżej",
            "face_distance_close": "Odsuń się dalej",
            "face_distance_fixed": "Idealnie!",
            "face_angle_deviation": "Obróć twarz do kamery",
            "face_angle_fixed": "Idealnie!",
            "lighting_insufficient": "Znajdź jaśniejsze miejsce",
            "lighting_excessive": "Znajdź ciemniejsze miejsce",
            "lighting_angle_deviation": "Obróć się w stronę źródła światła",
            "lighting_fixed": "Idealnie!",
            "face_pitch_angle_deviation": "Obróć twarz do kamery",
            "capturing_front": "Nie ruszaj się",
            "capturing_left": "Powoli poruszaj głową\naby zeskanować skórę",
            "capturing_right": "Powoli poruszaj głową\naby zeskanować skórę"
        },
        "hair_live_video": {
            "face_displaced": "Umieść twarz w ramce",
            "face_placement_fixed": "Idealnie!",
            "face_distance_close": "Odsuń się dalej",
            "face_distance_fixed": "Idealnie!",
            "lighting_insufficient": "Znajdź jaśniejsze miejsce",
            "lighting_fixed": "Idealnie!",
            "hair_not_visible": "Uczyń swoje włosy widocznymi",
            "hair_visibility_fixed": "Idealnie!",
            "face_angle_deviation": "Obróć twarz do kamery",
            "face_angle_fixed": "Idealnie!",
            "capturing": "Uczyń swoje włosy widocznymi\nZdjęcie zostanie zrobione automatycznie"
        },
        "preview": {
            "title": "Dobra robota!",
            "text": "Zobacz, jak pięknie wyglądasz",
            "retake": "Zrób ponownie",
            "submit": "Prześlij"
        }
    },
    "tr": {
        "source_selection": {
            "title": "Çekim seçeneğini seçin",
            "camera_permissions_warning": {
                "title": "Kamera erişimi reddedildi",
                "instructions_reset": "Kamerayı kullanabilmek veya cihazdan fotoğraf yüklemek için tarayıcınızın kamera izinlerini güncelleyin",
                "instructions_reload": "Kamerayı kullanabilmek veya cihazdan fotoğraf yüklemek için tarayıcınızın kamera izinlerini güncelleyin"
            },
            "camera_video": "Fotoğraf çek",
            "upload_photo": "Cihazdan yükle"
        },
        "face_live_video": {
            "face_displaced": "Yüzünüzü çerçevenin içine yerleştirin",
            "face_placement_fixed": "Mükemmel!",
            "face_distance_far": "Daha yakınlaşın",
            "face_distance_close": "Daha uzaklaşın",
            "face_distance_fixed": "Mükemmel!",
            "face_angle_deviation": "Yüzünüzü kameraya çevirin",
            "face_angle_fixed": "Mükemmel!",
            "lighting_insufficient": "Daha aydınlık bir yer bulun",
            "lighting_excessive": "Daha karanlık bir yer bulun",
            "lighting_angle_deviation": "Işık kaynağına dönün",
            "lighting_fixed": "Mükemmel!",
            "capturing": "Kameraya bakın"
        },
        "face_180_live_video": {
            "face_displaced": "Yüzünüzü çerçevenin içine yerleştirin",
            "face_placement_fixed": "Mükemmel!",
            "face_distance_far": "Daha yakınlaşın",
            "face_distance_close": "Daha uzaklaşın",
            "face_distance_fixed": "Mükemmel!",
            "face_angle_deviation": "Yüzünüzü kameraya çevirin",
            "face_angle_fixed": "Mükemmel!",
            "lighting_insufficient": "Daha aydınlık bir yer bulun",
            "lighting_excessive": "Daha karanlık bir yer bulun",
            "lighting_angle_deviation": "Işık kaynağına dönün",
            "lighting_fixed": "Mükemmel!",
            "face_pitch_angle_deviation": "Yüzünüzü kameraya çevirin",
            "capturing_front": "Hareketsiz durun",
            "capturing_left": "Cildinizi taramak için başınızı yavaşça sola hareket ettirin",
            "capturing_right": "Cildinizi taramak için başınızı yavaşça sağa hareket ettirin"
        },
        "hair_live_video": {
            "face_displaced": "Yüzünüzü çerçevenin içine yerleştirin",
            "face_placement_fixed": "Mükemmel!",
            "face_distance_close": "Daha uzaklaşın",
            "face_distance_fixed": "Mükemmel!",
            "lighting_insufficient": "Daha aydınlık bir yer bulun",
            "lighting_fixed": "Mükemmel!",
            "hair_not_visible": "Saçınızı görünür hale getirin",
            "hair_visibility_fixed": "Mükemmel!",
            "face_angle_deviation": "Yüzünüzü kameraya çevirin",
            "face_angle_fixed": "Mükemmel!",
            "capturing": "Saçınızı görünür hale getirin\nFotoğraf otomatik olarak çekilecektir"
        },
        "preview": {
            "title": "Harika!",
            "text": "Ne kadar güzel olduğunuzu kontrol edin",
            "retake": "Tekrar çek",
            "submit": "Gönder"
        }
    }


}